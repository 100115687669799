import './App.scss';

// Components & context
import Scrollbar from './components/Scrollbar';
import LoadingScreen from './components/LoadingScreen';
import Cursor from './components/Cursor'; // Changes cursor type
import CursorWrapper from './components/CursorWrapper'; // Hides cursor when mousing off page
import Hero from './components/Hero';
import Experience from './components/Experience';
import Skills from './components/Skills';
import SelectedWorks from './components/SelectedWorks';
import Contact from './components/Contact';

import { CursorProvider } from './components/CursorContext';

// Framer Motion & Lenis Smooth Scrolling
import { ReactLenis } from '@studio-freight/react-lenis';

// React hooks
import { useState, useEffect } from 'react';

function App() {

  const [loadingComplete, setLoadingComplete] = useState(false);

  // Prevent scrolling until loading is complete
  useEffect(() => {

    if (!loadingComplete) {
      document.body.setAttribute('data-lenis-prevent', 'true');
    } else {
      document.body.removeAttribute('data-lenis-prevent');
    }

    document.body.style.overflow = loadingComplete ? 'visible' : 'hidden';

  }, [loadingComplete]);

  const handleLoadingComplete = () => {
    setLoadingComplete(true);
  };

  return (
    <CursorProvider>
    <ReactLenis root>
    <CursorWrapper>
      {!loadingComplete && <LoadingScreen onComplete={handleLoadingComplete} />}

      <Hero loadingComplete={loadingComplete}/>

      <Experience loadingComplete={loadingComplete}/>

      <SelectedWorks/>

      <Skills/>

      <Contact loadingComplete={loadingComplete}/>

      <Scrollbar></Scrollbar>

      <Cursor></Cursor>

    </CursorWrapper>
    </ReactLenis>
    </CursorProvider>
  );
}

export default App;
