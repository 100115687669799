import { useRef } from "react";
import { motion, useInView } from 'framer-motion';
import Prism from './Prism';
import Link from './Link';

type ContactProps = {
  loadingComplete: boolean;
}
export default function Contact({loadingComplete}: ContactProps) {
  const imgRef = useRef(null);
  const imgIsInView = useInView(imgRef);

  return(
    <section id='contact' className='uppercase'>
      <div className='container flex justify-end relative border-top-accent'>
        <Prism sizeInVW={70} shape={'sphere'} delay={0} id={'prism-3'} loadingComplete={loadingComplete}></Prism>
        <div className='black-block absolute'></div>
        <motion.div 
          transition={{ duration: .5, ease: [0.075, 0.82, 0.165, 1], delay: .5}}
          initial={{scaleY:0}}
          animate={imgIsInView ? {scaleY: 1}:{}}          
          className='green-block-1 absolute'></motion.div>
        <motion.div
          transition={{ duration: .5, ease: [0.075, 0.82, 0.165, 1], delay: .65}}
          initial={{scaleY:0}}
          animate={imgIsInView ? {scaleY: 1}:{}}                    
          className='green-block-2 absolute'></motion.div>
        <motion.h1 
          transition={{ duration: .5, ease: [0.075, 0.82, 0.165, 1], delay: imgIsInView ? .5 : 0}}
          initial={{scaleY:0}}
          animate={imgIsInView ? {scaleY: 1}:{}}                    
          className='absolute part-1 z-10'>+ Let's work</motion.h1>
        <motion.h1 
          transition={{ duration: .5, ease: [0.075, 0.82, 0.165, 1], delay: imgIsInView ? .65 : 0}}
          initial={{scaleY:0}}
          animate={imgIsInView ? {scaleY: 1}:{}}         
          className='absolute part-2 z-10'>together</motion.h1>
        <motion.div
          transition={{ duration: .5, ease: [0.075, 0.82, 0.165, 1], delay: imgIsInView ? .8 : 0}}
          initial={{scaleY:0}}
          animate={imgIsInView ? {scaleY: 1}:{}}  
          className='linkedin absolute z-10 select-none'
        >
          <Link url={'https://www.linkedin.com/in/jesse-e-martinez/'} text={'Connect on LinkedIn'} isCTA={false}></Link>
        </motion.div>
        <motion.div 
          transition={{ duration: .5, ease: [0.075, 0.82, 0.165, 1], delay: imgIsInView ? 1.1 : 0}}
          initial={{x:100, opacity: 0}}
          animate={imgIsInView ? {x:0, opacity: 1}:{}}                                
          className='cta absolute right-0'>
          <Link url={''} text={''} isCTA={true}></Link>
        </motion.div>
        <div ref={imgRef} className='img-container z-10 relative overflow-hidden'>
          <img className='' src='/images/headshot.webp' alt=''></img>
          <motion.img
            ref={imgRef}
            transition={{ duration: .5, ease: [0.075, 0.82, 0.165, 1], delay: imgIsInView ? 1 : 0}}
            initial={{opacity:1}}
            animate={imgIsInView ? {opacity: 0}:{}}
            className='absolute top-0 left-0' src='/images/dither-headshot.png' alt=''></motion.img>
        </div>
      </div>
    </section>
  );
}