import React from 'react';
import { useCursor } from './CursorContext';

type CursorWrapperProps = {
  children: React.ReactNode;
}

export default function CursorWrapper({ children }: CursorWrapperProps) {
  const { enableCursor, disableCursor } = useCursor();

  return (
    <div className='App relative' onMouseEnter={enableCursor} onMouseLeave={disableCursor}>
      {children}
    </div>
  );
};