import { motion } from 'framer-motion';
import Prism from './Prism';
import Note from './Note';

type HeroProps = {
  loadingComplete: boolean;
}

export default function Hero({ loadingComplete }: HeroProps) {
  return (
    <section id='hero' className='container relative uppercase'>
    <motion.h1  
      transition={{ duration: 1, ease: [0.075, 0.82, 0.165, 1]}}
      initial={{x: -200, opacity: 0}}
      animate={loadingComplete ? {x: 0, opacity: 1} : {}}
      className='absolute z-10'>Software Engineer</motion.h1>
    <h1 
      className='absolute z-10'>
        <motion.span
          className='block'
          transition={{ duration: 1, ease: [0.075, 0.82, 0.165, 1], delay: .2}}
          initial={{x: -100, opacity: 0}}
          animate={loadingComplete ? {x: 0, opacity: 1} : {}}
        >specializing in</motion.span>
        <motion.span
          className='block'
          transition={{ duration: 1, ease: [0.075, 0.82, 0.165, 1], delay: .3}}
          initial={{x: -100, opacity: 0}}
          animate={loadingComplete ? {x: 0, opacity: 1} : {}}
          >interactive web</motion.span>
        <motion.span
        transition={{ duration: 1, ease: [0.075, 0.82, 0.165, 1], delay: .4}}
        initial={{x: -100, opacity: 0}}
        animate={loadingComplete ? {x: 0, opacity: 1} : {}}
        className='block'
        >experiences</motion.span>
          
    </h1>
    <motion.h1 
      transition={{ duration: 1, ease: [0.075, 0.82, 0.165, 1], delay: .6}}
      initial={{y: 100, opacity: 0}}
      animate={loadingComplete ? {y: 0, opacity: 1} : {}}
      className='absolute z-10'>for tech and</motion.h1>
    <motion.h1 
      transition={{ duration: 1, ease: [0.075, 0.82, 0.165, 1], delay: .7}}
      initial={{y: 100, opacity: 0}}
      animate={loadingComplete ? {y: 0, opacity: 1} : {}}
      className='absolute z-10'>creative teams</motion.h1>
    <Note
      text={'Currently a Systems Development Engineer at Twitch.tv'}
      typingAnimation={true}
      delayOffset={1}
      loadingComplete={loadingComplete}
    />
    <Prism sizeInVW={45} shape={'box'} delay={.5} id={'prism-1'} loadingComplete={loadingComplete}></Prism>
    <Prism sizeInVW={21} shape={'triangle'} delay={1.25} id={'prism-2'} loadingComplete={loadingComplete}></Prism>
  </section>
  )
}