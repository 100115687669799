import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import Plane from './Plane';
import Note from './Note';

type ExperienceProps = {
  loadingComplete: boolean;
}

export default function Experience({loadingComplete}: ExperienceProps) {
  const experienceRef = useRef(null);
  const expIsInView = useInView(experienceRef, { once: true });

  return(
    <section id='experience' className='uppercase w-full relative'>
      <div className='container text relative border-top-accent'>
        <motion.h2
          transition={{ duration: 1, ease: [0.075, 0.82, 0.165, 1], delay: .2}}
          initial={{x: -100, opacity: 0}}
          animate={loadingComplete && expIsInView ? {x: 0, opacity: 1} : {}}
        >Professional experience</motion.h2>
        <motion.h1
          transition={{ duration: 2, ease: [0.075, 0.82, 0.165, 1], delay: .3}}
          initial={{y: 10, opacity: 0}}
          animate={loadingComplete && expIsInView ? {y: 0, opacity: 1} : {}}
        >VMWare 2020&ndash;2024</motion.h1>
        <motion.h1
          ref={experienceRef}
          transition={{ duration: 2, ease: [0.075, 0.82, 0.165, 1], delay: .4}}
          initial={{y: 10, opacity: 0}}
          animate={loadingComplete && expIsInView ? {y: 0, opacity: 1} : {}}
        >Pivotal 2018&ndash;2020</motion.h1>
        <Note
          text={'At VMware & Pivotal, I designed and developed web applications that achieved hundreds of thousands of impressions, enhancing brand awareness and bolstering customer acquisition. From mockup to launch, I ensured all code adhered to the highest standards of performance, responsiveness & accessibility while emphasizing a unique user experiences.'}
          typingAnimation={false}
          delayOffset={.5}
          loadingComplete={loadingComplete}
        />
      </div>
      <Plane></Plane>
    </section>
  );
}