import Shooter from './Shooter';

export default function Skills() {
  return(
    <section id='skills' className='uppercase'>
      <div className='container'>
        <Shooter></Shooter>
      </div>
    </section>
  );
}